import * as React from "react";
import { CarWizardState } from "src/stores/wizard/state";
import { locKeys } from "../l10n";
import { couponCodeTypes } from "./CouponCodeTypes";
import { CarVendorInformation, CarTypeInformation } from "src/stores/wizard/config/staticConfigs/car/typings";
import { UitkLayoutGridItem, UitkLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";
import { UitkTextInput } from "@egds/react-core/input";
import { formatText } from "bernie-l10n/dist/localization";

export const CarType = (carWizardState: CarWizardState) => {
  const { cars, carType } = carWizardState.config;
  const carValue = Object.values(cars).filter(({ carCode }: CarTypeInformation) => carCode === carType);

  if (carValue.length === 0) return null;

  carWizardState.carType = formatText(carValue[0].locKey);
  const searchKey = `["${carValue[0].carCode.toLowerCase()}"]`;

  return (
    <div>
      <input data-testid="selCC" type="hidden" name="selCC" value={searchKey} />
    </div>
  );
};

export const CarVendorName = (carWizardState: CarWizardState, colSpan: UitkLayoutConditionalGridSpan) => {
  const { vendors, carVendorName } = carWizardState.config;

  const vendorName = Object.values(vendors).filter(
    ({ locKey }: CarVendorInformation) => formatText(locKey) === carVendorName
  );

  if (vendorName.length === 0) return null;

  carWizardState.setPreferredBrandWithoutSelect(vendorName[0].vendorCode);

  return (
    <UitkLayoutGridItem colSpan={colSpan}>
      <div>
        <UitkTextInput
          data-testid="vendor-name"
          label={formatText(locKeys.brandSelectionLabel)}
          value={formatText(vendorName[0].locKey)}
        />
        <input type="hidden" name="vend" value={carWizardState.preferredBrand} />
      </div>
    </UitkLayoutGridItem>
  );
};

export const CarDiscountType = (carWizardState: CarWizardState, colSpan: UitkLayoutConditionalGridSpan) => {
  const { corporateCode, specialCode, noCode, couponCodeSelectionLabel } = locKeys;
  const { CORPORATE_CODE, SPECIAL_CODE, NO_CODE } = couponCodeTypes;
  const { carDiscountTypeCode } = carWizardState.config;
  const couponCode = carDiscountTypeCode === "" ? NO_CODE : carDiscountTypeCode === "1" ? CORPORATE_CODE : SPECIAL_CODE;
  carWizardState.updateCouponCodeTypeWithoutSelect(couponCode);

  return (
    <UitkLayoutGridItem colSpan={colSpan}>
      <div>
        <UitkTextInput
          data-testid="coupon-type"
          label={formatText(couponCodeSelectionLabel)}
          value={
            carDiscountTypeCode === ""
              ? formatText(noCode)
              : carDiscountTypeCode === "1"
              ? formatText(corporateCode)
              : formatText(specialCode)
          }
        />
      </div>
    </UitkLayoutGridItem>
  );
};
