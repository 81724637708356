import * as React from "react";
import { observer } from "mobx-react";

import { UitkLayoutGrid } from "@egds/react-core/layout-grid";
import { UitkExpandoLink } from "@egds/react-core/expando";
import { UitkLinkSizes } from "@egds/react-core/link";
import { UitkSpacing } from "@egds/react-core/spacing";
import { useLocalization } from "@shared-ui/localization-context";

import { VendorCodesProps } from "components/flexComponents/WizardCarPWA/typings";
import { BrandDropdown } from "./BrandDropdown";
import { CouponCodeDropdown } from "./CouponCodeDropdown";
import { CouponCodeEntryFields } from "./CouponCodeEntryFields";
import { locKeys } from "../l10n";
import { CarVendorName, CarDiscountType, CarType } from "./CarUtils";

export const VendorCodes = observer(({ carWizardState, mainColSpan, colSpan }: VendorCodesProps) => {
  const { formatText } = useLocalization();
  const {
    carVendorName,
    carType,
    showCarDiscount,
    disableVendorCouponCode,
    iHaveDiscount,
    isBiasedWizardEmpty,
  } = carWizardState.config;

  const [isExpandoVisible, setIsExpandoVisible] = React.useState(Boolean(carVendorName || carType));

  return (
    <UitkSpacing margin={{ blockstart: "three" }}>
      <UitkExpandoLink
        size={UitkLinkSizes.get("medium")}
        expandoTitle={formatText(iHaveDiscount)}
        isVisible={isExpandoVisible}
        onToggle={() => setIsExpandoVisible((prevIsVisible) => !prevIsVisible)}
        data-testid="vendor-have-discount-code"
      >
        {!isBiasedWizardEmpty && carType && CarType(carWizardState)}
        <UitkSpacing margin={{ blockstart: "three" }}>
          <UitkLayoutGrid columns={mainColSpan} space="three">
            {!carVendorName && (
              <BrandDropdown
                label={formatText(locKeys.brandSelectionLabel)}
                lobState={carWizardState}
                colSpan={colSpan}
              />
            )}
            {carVendorName && CarVendorName(carWizardState, colSpan)}

            {!disableVendorCouponCode && !showCarDiscount && (
              <CouponCodeDropdown
                label={formatText(locKeys.couponCodeSelectionLabel)}
                carWizardState={carWizardState}
                colSpan={colSpan}
              />
            )}
            {!disableVendorCouponCode && showCarDiscount && CarDiscountType(carWizardState, colSpan)}

            <CouponCodeEntryFields
              carWizardState={carWizardState}
              vendor={carWizardState.preferredBrand}
              colSpan={colSpan}
            />
          </UitkLayoutGrid>
        </UitkSpacing>
      </UitkExpandoLink>
    </UitkSpacing>
  );
});
