import * as React from "react";
import { UitkTextInput } from "@egds/react-core/input";
import { UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { useLocalization } from "@shared-ui/localization-context";
import { CouponInputFieldProps } from "components/flexComponents/WizardCarPWA/typings";
import { observer } from "mobx-react";

const CouponInputField = observer(({ inputField, carWizardState, index, colSpan }: CouponInputFieldProps) => {
  const { formatText } = useLocalization();
  const { carDiscountCode, showCarDiscount } = carWizardState.config;
  const { name } = inputField;
  const couponCPCDcode = "cpcd";
  const couponCDNUcode = "cdnu";

  const couponValue = carDiscountCode && (name === couponCPCDcode || name === couponCDNUcode) ? carDiscountCode : "";

  return (
    <UitkLayoutGridItem colSpan={colSpan}>
      <div>
        <UitkTextInput
          id={`coupon-code-${index}`}
          label={formatText(inputField.labelToken)}
          onChange={carWizardState.updateCouponCode(index)}
          value={showCarDiscount ? couponValue : carWizardState[`couponCode${index}`]}
        />
        <input
          type="hidden"
          name={inputField.name}
          value={showCarDiscount ? couponValue : carWizardState[`couponCode${index}`]}
          data-testid={`coupon-code-${index}`}
        />
      </div>
    </UitkLayoutGridItem>
  );
});

export default CouponInputField;
